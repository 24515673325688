import { ajaxService } from '@/utils/request';

const env = import.meta.env;
const baseURL = env.VITE_APP_BASE_URL;
const appURL = env.VITE_UPLOAD_URL;

// 获取人员的文件中心配置信息
export function GetDocumentCenterListAll(params: any) {
  return ajaxService({
    url: '/hr/hr_file/user/file/config',
    method: 'get',
    baseURL,
    params
  });
}

// 修改人员的文件中心配置信息
export function PutStorageDisk(data: any) {
  return ajaxService({
    url: '/hr/hr_file/user/file/config',
    method: 'put',
    baseURL,
    data
  });
}

// 新增文件所属分类
export function PostStorageDisk(data: any) {
  return ajaxService({
    url: '/hr/hr_file/user/file/belong',
    method: 'post',
    baseURL,
    data
  });
}

// 查询文件所属分类
export function getFileDelong(params?: any) {
  return ajaxService({
    url: '/hr/hr_file/user/file/belong',
    method: 'get',
    baseURL,
    params
  });
}

// 删除个人文件
export function deleteFile(params: any) {
  return ajaxService({
    url: '/lke_file/file',
    method: 'delete',
    baseURL: appURL,
    params
  });
}

// 获取公共文件夹
export function getPubliceFileBelong() {
  return ajaxService({
    url: '/hr/hr_file/public/user/file/belong',
    method: 'get',
    baseURL
  });
}

// 获取共享列表
export function getShareList(params: any) {
  return ajaxService({
    url: '/hr/hr_file/share/file',
    method: 'get',
    baseURL,
    params
  });
}

// 修改共享状态
export function putShareState(data: { id: number }) {
  return ajaxService({
    url: '/hr/hr_file/file/share/state',
    method: 'put',
    baseURL,
    data
  });
}

// 获取收藏列表
export function getCollectList(params: any) {
  return ajaxService({
    url: '/hr/hr_file/collect/file',
    method: 'get',
    baseURL,
    params
  });
}

// 新增收藏文件
export function postFileCollect(data: { fileId: number; userId: number }) {
  return ajaxService({
    url: '/hr/hr_file/user/file/collect',
    method: 'post',
    baseURL,
    data
  });
}

// 删除收藏文件
export function delFileCollect(params: any) {
  return ajaxService({
    url: '/hr/hr_file/collect',
    method: 'delete',
    baseURL,
    params
  });
}

// 租户云盘管理
export function getClientDisk() {
  return ajaxService({
    url: '/hr/hr_file/client/disk',
    method: 'get',
    baseURL
  });
}

// 查询租户云盘空间使用情况
export function getClientStorageSpace() {
  return ajaxService({
    url: '/hr/hr_file/client/storage/space',
    method: 'get',
    baseURL
  });
}

// 修改单个云盘空间大小
export function putClientStorageSpace(data: { id: number; diskSpace: number }) {
  return ajaxService({
    url: '/hr/hr_file/client/storage/space',
    method: 'put',
    baseURL,
    data
  });
}

// 租户根据用户数量平均分配空间
export function getHumanStorageSpace() {
  return ajaxService({
    url: '/hr/hr_file/human/space/into',
    method: 'get',
    baseURL
  });
}

// 平均分配个人盘大小
export function postUserDisk(data: { type: number }) {
  return ajaxService({
    url: '/hr/hr_file/user/disk',
    method: 'post',
    baseURL,
    data
  });
}

// 修改或新增个人盘大小
export function putUserDisk(data: { type: number }) {
  return ajaxService({
    url: '/hr/hr_file/user/disk',
    method: 'put',
    baseURL,
    data
  });
}

// 查询用户个人盘(分页)
export function getUserDisk(params: { type: number }) {
  return ajaxService({
    url: '/hr/hr_file/user/disk',
    method: 'get',
    baseURL,
    params
  });
}

// 人员列表
export function getUserList(params: any) {
  return ajaxService({
    url: '/hr/hr_file/user',
    method: 'get',
    baseURL,
    params
  });
}

// 上传文件前的校验
export function FileUploadVerification(params: any) {
  return ajaxService({
    url: '/hr/hr_file/breakpoint/resume/upload',
    method: 'get',
    baseURL,
    params
  });
}

// 上传文件后的修改云盘信息
export function putFileUploadInfo(data: any) {
  return ajaxService({
    url: '/hr/hr_file/breakpoint/resume/upload',
    method: 'put',
    baseURL,
    data
  });
}

// 删除临时文件
export function delFileUploadInfo(params: any) {
  return ajaxService({
    url: '/lke_file/tmp/file',
    method: 'delete',
    baseURL: appURL,
    params
  });
}

// 新增存储盘
export function postClientDisk(data: any) {
  return ajaxService({
    url: '/hr/hr_file/client/disk',
    method: 'post',
    baseURL,
    data
  });
}

// 重命名
export function putFileName(data: any) {
  return ajaxService({
    url: '/hr/hr_file/file/name',
    method: 'put',
    baseURL,
    data
  });
}

// 置顶
export function putFileTopping(data: any) {
  return ajaxService({
    url: '/hr/hr_file/top/state',
    method: 'put',
    baseURL,
    data
  });
}

// 新增知识库
export function postknowledgeBase(data: any) {
  return ajaxService({
    url: '/hr/hr_file/knowledge',
    method: 'post',
    baseURL,
    data
  });
}

// 查询知识库
export function getknowledgeBase(params: any) {
  return ajaxService({
    url: '/hr/hr_file/knowledge',
    method: 'get',
    baseURL,
    params
  });
}

// 查询知识库
export function getOneknowledgeBase(params: any) {
  return ajaxService({
    url: '/hr/hr_file/one/knowledge',
    method: 'get',
    baseURL,
    params
  });
}
